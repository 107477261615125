






import { Component, Vue } from 'vue-property-decorator'

import MedicalPatientForm from '@/partials/forms/Medical/PatientForm.vue'

@Component({
  components: {
    MedicalPatientForm
  }
})
export default class MedicalPatientCreate extends Vue {

}
